@font-face {
	font-family: SFProRegular;
	src: local("SFProRegular"),
    url("./fonts/SFProRegular.otf") format("opentype");
}

@font-face {
	font-family: SFProMedium;
	src: local("SFProMedium"),
    url("./fonts/SFProMedium.otf") format("opentype");
}

:root {
	--text: white;
	--background: black;
}

html * {
	-ms-overflow-style: none;
  	scrollbar-width: none;
	transition: none;
}

::-webkit-scrollbar {
	display: none;
}

body {
	font-family: SFProRegular, sans-serif;
	color: var(--text);
	height: 91vh;
}

.wrapper {
	background: var(--background);
    margin: 0px;
	height: 91vh;

}

.center_container {
	position: relative;
  	height: 91vh;
}

.centered {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	max-height: 100vh;
	width: 100%;
	overflow: auto;
}

.container {
	text-align: center;
	background: #1c1c1e;
	margin: 0 auto;
	border-radius: 15px;
	padding: 10px;
	padding-top: 25px;
	padding-bottom: 35px;
	box-sizing: border-box;
	width: 89%;
	border-style: solid;
	border-width: 1.5px;
	border-color: rgba(255, 255, 255, 0.1);
	box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.3);
}
.container_full {
	text-align: left;
	background: #1c1c1e;
	margin: 0 auto;
	border-radius: 15px;
	padding: 25px;
	padding-top: 25px;
	padding-bottom: 35px;
	box-sizing: border-box;
	width: 89%;
	border-style: solid;
	border-width: 1.5px;
	border-color: rgba(255, 255, 255, 0.1);
	margin-top: 30px;
	box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.3);
}

.title {
	font-family: SFProRegular;
	font-size: 54px;
	margin-bottom: 5px;
}
.subtitle {
	font-family: SFProRegular;
	font-size: 34px;
	margin-bottom: 30px;
}
.heading {
	font-family: SFProMedium;
	font-size: 20px;
	margin-bottom: 5px;
}

.text {
	font-family: SFProRegular;
	font-size: 18px;
	line-height: 1.5;
	color: rgba(255, 255, 255, 0.8);
}

.image {
	width: 95px;
	height: 95px;
	border-radius: 20px;
	border-style: solid;
	border-width: 1px;
	border-color: rgba(255, 255, 255, 0.2);
	user-select: none;
	box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.3);
}

.button1 {
	font-family: SFProMedium;
	background: var(--text);
	color: var(--background);
	font-size: 20px;
	transition: 0.1s;
	padding: 10px;
	padding-left: 25px;
	padding-right: 25px;
	border-radius: 50px;
	box-sizing: border-box;
	width: max-content;
	height: 55px;
	text-decoration: none !important;
	opacity: 1;
	border: none;
}
.button1:hover {
	cursor: pointer;
	opacity: 0.85 !important;
}
.button1:active {
	transform: scale(0.99);
}

 /* DESKTOP STYLES */

@media only screen and (min-width: 787px) 
{
	.container {
		width: 380px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.container_full {
		max-width: 700px;
	}
}